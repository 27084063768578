@import url("https://fonts.googleapis.com/css?family=Product+Sans");

$text-color: #828286;
$subtitle-color: #b8b8ba;
$background-color: #f7f7f7;
$shadow-color: #9d9d9d;
$hover-color: #f3f3f3;

$text-color-dark: #f7f7f7;
$subtitle-color: #a09f9f;
$background-color-dark: #39393b;
$shadow-color-dark: #1b1b1b;
$hover-color-dark: #2c2c2c;

$highlight-color: #f47c58;

$full-height: 100vh;
$full-width: 100vw;
$duration: 0.5s;

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

body {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    font-family: "Product Sans", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
}

#app {
    color: $text-color;
    box-sizing: border-box;
    background-color: $background-color;
    display: flex;
    transition-property: background-color, color, box-shadow;
    transition-duration: $duration, $duration, $duration;

    > div {
        flex: 1 1 auto;
        content: "";
    }

    #app-inner {
        box-sizing: border-box;
        background-color: $background-color;
        flex: 0 1 90rem;

        transition-property: background-color;
        transition-duration: $duration;
    }

    a {
        color: $text-color;
    }
}

@media only screen and (max-width: 700px) {
    #app-inner {
        padding: 0 1rem;
    }
}

@media only screen and (min-width: 701px) {
    #app-inner {
        padding: 0 2rem;
    }
}

#app.dark {
    color: $text-color-dark;
    background-color: $background-color-dark;

    #app-inner {
        background-color: $background-color-dark;
    }

    a {
        color: $text-color-dark;
    }
}

#footer {
    margin-top: 3rem;
    height: 30px;
    text-align: center;
    font-size: 0.9rem;
}

#footer svg {
    margin-right: 6px;
}

.error {
    color: red;
}

.title {
    font-size: 3em;
    font-weight: bold;
    padding-top: 60px;
}

hr {
    margin-top: 2px;
    border: 0;
    border-top: 1px solid $highlight-color;
}

#header-title {
    color: $highlight-color;
}

/**
CONTACT CSS
 */

#contact-container > div {
    width: 300px;
    margin: 3rem auto 0 auto;
    display: flex;
}

#contact-container a {
    all: unset;
    flex: 1 1 auto;
    cursor: pointer;

    transition-property: transform;
    transition-duration: 0.2s;
}

#contact-container svg {
    display: block;
    margin: auto;
}

#contact-container > div > a:hover {
    transform: scale(1.2);
}

/**
HEADER CSS
 */

#header,
#dropdown {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    background: white;
    box-shadow: 1px -2px 15px $shadow-color;

    transition-property: background-color, box-shadow;
    transition-duration: $duration, $duration;
}

#app.dark #header,
#app.dark #dropdown {
    background: $background-color-dark;
    box-shadow: 1px -2px 15px $shadow-color-dark;
}

#theme-switcher {
    float: right !important;
    display: block !important;
}

#theme-switcher:hover {
    background: transparent !important;
}

#header.is-active,
#app.dark #header.is-active {
    box-shadow: 1px -2px 15px transparent;
}

#dropdown {
    top: 58px;
    z-index: 9;
}

#header > div {
    float: left;
    padding: 20px;
    cursor: pointer;
}

#header > div:first-child {
    display: block !important;
    color: $highlight-color;
}

#header .active {
    background: $hover-color;
    transition-property: background-color;
    transition-duration: $duration;
}

#header > div:hover {
    background: $hover-color;
}

#app.dark #header > div:hover {
    background: $hover-color-dark;
}

#app.dark #header .active {
    background: $hover-color-dark;
}

#menu {
    float: right !important;
    padding: 15px !important;
}

#dropdown {
    display: none;
}

#dropdown.is-active {
    display: block;
}

#dropdown > div {
    width: 100%;
    padding: 20px;
    cursor: pointer;
}

#dropdown > div:hover {
    background: $hover-color;
}

#app.dark #dropdown > div:hover {
    background: $hover-color-dark;
}

@media only screen and (max-width: 700px) {
    #menu {
        display: block !important;
    }

    #header > div {
        display: none;
    }
}

@media only screen and (min-width: 701px) {
    #menu {
        display: none;
    }

    #dropdown {
        display: none !important;
    }
}

/**
INFO CSS
 */

#info {
    box-sizing: border-box;
    min-height: $full-height;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:nth-child(2) {
        flex: 0 1 auto;
    }

    > div {
        display: flex;
        flex: 1 1 auto;

        > div:first-child {
            padding: 1rem;
            flex: 1 0 300px;
            box-sizing: border-box;
        }

        > div:last-child {
            padding: 1rem;
            flex: 0 1 50%;
            box-sizing: border-box;
        }
    }
}

#profile {
    @include aspect-ratio(1, 1);
    width: 334px;
    max-width: 100%;
    float: right;
    border-radius: 50%;
    box-shadow: 1px 1px 20px $shadow-color;
    overflow: hidden;
    transition-property: box-shadow;
    transition-duration: $duration;

    img {
        width: 100%;
        height: 100%;
    }
}

#app.dark #profile {
    box-shadow: 1px 1px 20px $shadow-color-dark;
}

@media only screen and (max-width: 800px) {
    #info > div {
        flex-direction: column;
        padding: 0 !important;
    }

    #profile {
        margin: auto;
        float: initial;
    }
}

@media only screen and (min-width: 801px) {
    #info > div {
        padding: 0 3rem;
    }
}

/**
PROJECT CSS
 */

.project-container {
    box-sizing: border-box;
    padding-top: 1.5rem;
    width: 100%;
    position: relative;
}

.full-container {
    width: 100%;
}

.project-text {
    box-sizing: border-box;
    float: left;
}

.project-title {
    font-size: 1.7em;
    margin-bottom: 10px;
    font-weight: bold;
}

.project-inner {
    @include aspect-ratio(16, 9);
    position: relative;
    box-shadow: 1px 1px 30px $shadow-color;
    overflow: hidden;
    margin-bottom: 2rem;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -khtml-border-radius: 20px;
    transition-property: box-shadow;
    transition-duration: $duration;
    background: #ccc;
    opacity: 0.99;
}

.project-inner > .content > div:first-child {
    height: 100%;
}

.project-links svg:hover {
    transform: scale(1.2);
}

.project-links {
    padding-top: 10px;
    font-size: 1.5rem;

    > a:first-child {
        margin-left: 0;
    }

    > a {
        all: unset;
        margin-left: 10px;
        cursor: pointer;
    }
}

.project-links svg {
    transition-property: transform;
    transition-duration: 0.2s;
}

#app.dark .project-inner {
    box-shadow: 1px 1px 30px $shadow-color-dark;
}

@media only screen and (max-width: 1150px) {
    .project-inner {
        width: 100%;
    }

    .project-text {
        width: 100%;
        padding-bottom: 1rem;
    }
}

@media only screen and (min-width: 1151px) {
    .project-inner {
        width: 75%;
    }

    .project-inner.left {
        float: right;
    }

    .project-inner.right {
        float: left;
    }

    .project-text {
        height: 100%;
        width: 25%;
    }

    .project-text.left {
        padding-left: 25px;
        float: right;
    }

    .project-text.right {
        padding-right: 25px;
        text-align: right;
        float: left;
    }
}

.project-inner img {
    box-shadow: 1px 1px 15px $shadow-color-dark;
}

/*******************************************************************
** LANDSCAPE 1 *****************************************************
*******************************************************************/

.project-inner.landscape-1 img {
    width: 90%;
    transform: rotate(-20deg) translate(5%, 10%);
}

/*******************************************************************
** LANDSCAPE 3 *****************************************************
*******************************************************************/

.project-inner.landscape-3 img {
    width: 70%;
}

.project-inner.landscape-3 img:nth-child(1) {
    transform: rotate(-20deg) translate(-30%, -30%);
}

.project-inner.landscape-3 img:nth-child(2) {
    transform: rotate(-20deg) translate(-11%, -10%);
}

.project-inner.landscape-3 img:nth-child(3) {
    transform: rotate(-20deg) translate(117%, -150%);
}

/*******************************************************************
** LANDSCAPE 4 *****************************************************
*******************************************************************/

.project-inner.landscape-4 img {
    width: 60%;
}

.project-inner.landscape-4 img:nth-child(1) {
    transform: rotate(-20deg) translate(-30%, -10%);
}

.project-inner.landscape-4 img:nth-child(2) {
    transform: rotate(-20deg) translate(10%, 15%);
}

.project-inner.landscape-4 img:nth-child(3) {
    transform: rotate(-20deg) translate(120%, -200%);
}

.project-inner.landscape-4 img:nth-child(4) {
    transform: rotate(-20deg) translate(160%, -175%);
}

/*******************************************************************
** PORTRAIT 5 ******************************************************
*******************************************************************/

.project-inner.portrait-5 img {
    width: 30%;
}

.project-inner.portrait-5 img:nth-child(1) {
    transform: rotate(-20deg) translate(-20%, 10%);
}

.project-inner.portrait-5 img:nth-child(2) {
    transform: rotate(-20deg) translate(0, 60%);
}

.project-inner.portrait-5 img:nth-child(3) {
    transform: rotate(-20deg) translate(-94%, -68%);
}

.project-inner.portrait-5 img:nth-child(4) {
    transform: rotate(-20deg) translate(270%, 10%);
}

.project-inner.portrait-5 img:nth-child(5) {
    transform: rotate(-20deg) translate(176%, -118%);
}

/*******************************************************************
** PORTRAIT 6 ******************************************************
*******************************************************************/

.project-inner.portrait-6 img {
    width: 30%;
}

.project-inner.portrait-6 img:nth-child(1) {
    transform: rotate(-20deg) translate(-15%, -40%);
}

.project-inner.portrait-6 img:nth-child(2) {
    transform: rotate(-20deg) translate(-109%, 49%);
}

.project-inner.portrait-6 img:nth-child(3) {
    transform: rotate(-20deg) translate(-87%, -50%);
}

.project-inner.portrait-6 img:nth-child(4) {
    transform: rotate(-20deg) translate(162%, 2%);
}

.project-inner.portrait-6 img:nth-child(5) {
    transform: rotate(-20deg) translate(182%, -100%);
}

.project-inner.portrait-6 img:nth-child(6) {
    transform: rotate(-20deg) translate(88%, -11%);
}

/**
ERVARING CSS
 */

#ervaring-container {
    display: flex;
    justify-content: space-between;
}

#ervaring-container > div {
    box-sizing: border-box;
    background: white;
    box-shadow: 1px 1px 15px $shadow-color;
    padding: 1rem;
    border-radius: 20px;
    flex: 0 1 24%;
    margin-top: 1rem;
    transition-property: background-color, box-shadow;
    transition-duration: $duration, $duration;
}

#app.dark #ervaring-container > div {
    background: #444446;
    box-shadow: 1px 1px 15px $shadow-color-dark;
}

#ervaring-container hr {
    margin: 5px;
}

.ervaring-title {
    text-align: center;
    font-weight: bold;
    font-size: 1em;
}

.ervaring {
    padding: 0.5rem 0;

    div {
        display: flex;
        margin-bottom: 4px;

        svg {
            flex: 0 0 4rem;
        }

        .image {
            flex: 0 0 4rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            margin-top: 7px;

            img {
                height: 100%;
            }
        }

        .text {
            flex: 1 1 auto;
            margin-top: 7px;
            display: flex;
            flex-direction: column;

            .subtitle {
                font-size: 0.6em;
                font-weight: bold;
                color: $subtitle-color;
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    #ervaring-container {
        flex-direction: column;
    }
}

@media only screen and (min-width: 851px) {
    #ervaring-container {
        flex-direction: row;
    }
}

/**
ANIMATION
*/

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
    opacity: 0;
}

@media only screen and (min-width: 851px) {
    .animationWait0 {
        animation-delay: 0ms;
    }

    .animationWait1 {
        animation-delay: 200ms;
    }

    .animationWait2 {
        animation-delay: 400ms;
    }
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
